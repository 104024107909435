import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';
import logoGrisGW from './img/logos/Google_Workspace_256x34px_Grey_clear.png';
import logoPlugnPlay from './img/logos/plugandplay256x34.png';
import batchCert from './img/logos/batchgc.png';
import mapsMarker from './img/logos/mapsmarker.png';
import './About.css'
const About = () => {
    return(
    <div className="About">
        <Header/> 
		<section id="content">
			
			<section id="about" className="content-row content-row-color content-row-clouds content-row-full-height">
				<div className="container">
					<header className="content-header ">
						<h1>
							Acerca de Nuberia
						</h1>
						
					</header>
				
				<div className="column-row align-center-bottom text-align-center">
					<div className="column-33">
							<img src={logoGrisGW} alt=""  className='logos'/>
							<h3>
								Partner
							</h3>
							<p>
								Partner Manejado de Google México
							</p>
							
						</div>
					<div className="column-33">
							
							<h3>
								Parte de un grupo con: 
							</h3>
							<p>
								<ul>
									<li>
										Más de <strong>20 años de experiencia</strong>  en nube

									</li>
									<li>
										 <strong>+500 Clientes </strong>  en nube

									</li>
									<li>
										Presencia en todo México

									</li>
								</ul>
							</p>
							
						</div>
					<div className="column-33">
							<img src={logoPlugnPlay} alt="" className='logos'/> 
							<h3>
								 Es nuestro Mantra
							</h3>
							
					</div>
				
				
				</div>
				<div className="column-row align-center-bottom text-align-center">
					<div className="column-33">
							
							<img src={batchCert} alt="" className='large-logos'/>
							<h3>
								Certificaciones:
							</h3>
							<p>
								<ul>
									<li>
										Google Cloud Sales 
									</li>
									<li>
										Google Workspace Deployment
									</li>
									<li>
										G Suite Sales Fundamentals
									</li>
								</ul>
							</p>
							
					</div>
					<div className="column-33">
						<div className='location'>
						<img src={mapsMarker} alt="" className='large-logos'/>
							
							<h3>
								Ubicación
							</h3>
							<p>
								Santa Fe, CDMX
							</p>
						</div>
							
						
								
					</div>	
				</div>
				</div>
				
			</section>
		</section>
        <Footer/>
     
    </div>
    )
}

export default About;