import './main.min.css';
// import Button from 'react-bootstrap/Button';
// import { Container, Row } from 'react-bootstrap';
// import logoBN from './g2504.png';
import logoGMAil from './img/logos/logo_gmail_2020q4_color_1x_web_96dp.png';
import logoDrive from './img/logos/logo_drive_2020q4_color_1x_web_96dp.png';
import logoMeet from './img/logos/logo_meet_2020q4_color_1x_web_96dp.png';
import logoCalendar from './img/logos/logo_calendar_2020q4_color_1x_web_96dp.png';
import logoDocs from'./img/logos/g134.png';
import logoChats from './img/logos/logo_chat_2020q4_color_1x_web_96dp.png';
const BoxesWorkspace= () => {
    return ( 
   
        <section  className="content-row">
        <div className="container">
            <header className="content-header">
                <h2>
                    Ofimática de nivel empresarial
                </h2>
                <p>
                    Servicios dessarrollados por Google
                </p>
            </header>
            <div className="column-row align-center-bottom text-align-center">
                <div className="column-33">
                    <img src={logoGMAil} alt="" style={{ width :"100px"}}/>
                    <h3>
                        Gmail
                    </h3>
                    <p>
                        Correo electrónico empresarial
                    </p>
                    <p>
                        <a href="https://workspace.google.com/intl/es-419/products/gmail/">Más información<i className="fas fa-angle-right icon-right"></i></a>
                    </p>
                </div>
                <div className="column-33">
                    <img src={logoDrive} alt="" style={{ width :"100px"}}/>
                    <h3>
                        Google Drive
                    </h3>
                    <p>
                        Almacenamiento en la nube seguro
                    </p>
                    <p>
                        <a href="https://workspace.google.com/intl/es-419/products/drive/">Más información<i className="fas fa-angle-right icon-right"></i></a>
                    </p>
                </div>
                <div className="column-33">
                    <img src={logoCalendar} alt="" style={{ width :"100px"}}/>
                    <h3>
                        Google Calendar
                    </h3>
                    <p>
                        Calendarios compartidos para tu equipo.
                    </p>
                    <p>
                        <a href="https://workspace.google.com/intl/es-419/products/calendar/">Más información<i className="fas fa-angle-right icon-right"></i></a>
                    </p>
                </div>
            </div>
            <div className="column-row align-center-bottom text-align-center">
                <div className="column-33">
                    <img src={logoMeet} alt="" style={{ width :"100px"}}/>
                    <h3>
                        Google Meet
                    </h3>
                    <p>
                        Reuniones de video y de voz
                    </p>
                    <p>
                        <a href="https://workspace.google.com/intl/es-419/products/meet/">Más información<i className="fas fa-angle-right icon-right"></i></a>
                    </p>
                </div>
                <div className="column-33">
                    <img src={logoDocs} alt="" style={{ width :"100px"}}/>
                    
                    <h3>
                        Docs, Slides y Sheets 
                    </h3>
                    <p>
                        Creación de contenido colaborativo
                    </p>
                    <p>
                        
                            <a href="./404.html">Docs, </a>
                            <a href="./404.html">Sheets, </a>
                            <a href="./404.html">Slides<i className="fas fa-angle-right icon-right"></i></a>
                        
                    </p>
                        
                    
                </div>
                <div className="column-33">
                    <img src={logoChats} alt="" style={{ width :"100px"}}/>
                    <h3>
                        Google Chat 
                    </h3>
                    <p>
                        Mensajería integrada para equipos
                    </p>
                    <p>
                        <a href="https://workspace.google.com/intl/es-419/products/chat/">Más información<i className="fas fa-angle-right icon-right"></i></a>
                    </p>
                </div>
            </div>
            
            
        </div>
    </section>
        
       
        
     );
}
 
export default BoxesWorkspace;