// import icono from './img/icons/favicon.ico'
// import iconApple from "./img/icons/apple-touch-icon.png";
// import './main.min.css';
import'./template/css/main.css';
// import {Link} from 'react-router-dom';
// import logoBN from './g2504.png';

const Head = () => {
	return (
	<header id="head">
		<meta charSet="UTF-8"/>
		<title>Nuberia Mexico </title>
		<meta name="description" content="Nuberia es una empresa mexicna destinada a la implementacion de servicios de google "/>
		<meta name="keywords" content="html template, responsive, retina,technology, startup"/>
		<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
		{/* <!-- Icons --> */}
		{/* <link rel="apple-touch-icon-precomposed" href={iconApple}/> */}
		{/* <link rel="ico" src={icono}/> */}
		{/* <!-- Stylesheets --> */}
		<link rel="stylesheet" href="./css/fontawesome.min.css"/>
		<link rel="stylesheet" href="./main.min.css"/>
	</header>
		  
	  );
}
 
export default Head;