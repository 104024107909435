
import Home from './Home';
import './main.min.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import PageNotFound from './404-page'
import productDomainName from './productDomainNames';
import clientLogin from './clientLogin';
import homeSinglePage from './homeSinglePage';
import React from 'react';
import resetPassword from './resetPassword';
import productWorkspace from './productWorkspace';
import productAdmin from './productAdmin';
import productMigrations from './productsMigrations';
import productWorkspaceEducation from './productsWorkspaceEdu';
import Contact from './Contact';
import About from './About';
import { Helmet } from "react-helmet"
import Profile  from './views/profile';
import ProtectedRoute from './auth/protected-route';
import ExternalApi from './API/external-API';
import RegisterForm from './RegisterForm';


function App(){

  return (
    <React.Fragment>
      <Helmet>
      <script type="text/javascript">
   {` (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "c9a5rhaatw");`}
</script>
      </Helmet>
    <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/products-domain-page" component={productDomainName} />
          <Route exact path="/login" component={clientLogin} />
          <Route exact path="/single-page" component={homeSinglePage} />
          <Route exact path="/reset-password" component={resetPassword}/>
          <Route exact path="/products-workspace" component={productWorkspace}/>
          <Route exact path="/products-administracion" component={productAdmin}/>
          <Route exact path="/migraciones" component={productMigrations}/>
          <Route exact path="/products-workspace-education" component={productWorkspaceEducation}/>
          <Route exact path="/acerca-de-nuberia" component={About}/>
          <Route exact path="/contacto" component={Contact}/>
          <Route exact path="/registro" component={RegisterForm}/>
          <ProtectedRoute exact path="/perfil" component={Profile}/>
          <ProtectedRoute path="/external-api" component={ExternalApi} />
          
          <Route component={PageNotFound} />
        </Switch>
    </Router>
    </React.Fragment>
  );

}

export default App;


