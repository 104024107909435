import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './styles/logout-button.css'

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <button
      className="logout-button"
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    > 
    <i className="fas fa-lock-open icon-left"></i>
      Salir
    </button>
  );
};

export default LogoutButton;