import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './registerForm.css';
import Header from './Header';
function RegisterForm() {
    const [name , setName] = useState('');
    const [company , setCompany] = useState('');
    const [email , setEmail] = useState('');
    const [rfc , setRfc] = useState('');
    const [dominio , setDominio] = useState('');
    const [representante , setRepresentante] = useState(false);
    const [numlicencias , setNumLicencias] = useState(0);
    const [tipoLicencias, setTipoLicencias] = useState('')

 
    // function to update state of name with
    // value enter by user in form
    const handleChange =(e)=>{
      setName(e.target.value);
    }
    // function to update state of age with value
    // enter by user in form
    const handleCompanyChange =(e)=>{
      setCompany(e.target.value);
    }
    // function to update state of email with value
    // enter by user in form
    const handleEmailChange =(e)=>{
      setEmail(e.target.value);
    }
      // function to update state of password with
      // value enter by user in form
    const handleRfcChange =(e)=>{
      setRfc(e.target.value);

    
    }

    const handleDominio=(e)=>{
      setDominio(e.target.value);
    }


    const handleSubmit=(e)=>{
    
      e.preventDefault();
      console.log(name,company,email,rfc,dominio, representante,numlicencias)
 
    }
    const handleResponable=(e)=>{
        setRepresentante(!representante)

    }

    const handleNumLicencias=(e)=>{

        setNumLicencias(e.target.value);


    }

    const handleTipoLicencias=(e)=>{

      setTipoLicencias(e.target.value);


  }

    
  return (
   <div>
    <Header/>
    
    <div className="R-Form">
    <header className="App-header">
    <form onSubmit={(e) => {handleSubmit(e)}}>
     {/*when user submit the form , handleSubmit()
        function will be called .*/}
    <div className='title'> Nuberia </div>
    <h2> Registrate</h2>
    <br/>
    <div className='section'>
           <label >
          Nombre:
        </label><br/>
        <input type="text" value={name} required onChange={(e)=> {handleChange(e)}} /><br/>
       

    </div>
    <div className='section'>
    <label >
          Compañia:
        </label><br/>
        <input type="text" value={company} required onChange={(e)=> {handleCompanyChange(e)}} /><br/>
       

    </div>
   
    <div className='section'>

         
        <label>
          Email:
        </label><br/>
        <input type="email" value={email} required onChange={(e)=> {handleEmailChange(e)}} /><br/>
       
       

    </div>

        <div className='section'>

             
        <label>
          RFC:
        </label><br/>
        <input type="text" value={rfc} required onChange={(e)=> {handleRfcChange(e)}} /><br/>
       
       

    </div>



        <div className='section'>

            
        <label>
          Dominio:
        </label><br/>
        <input type="text" value={dominio} required onChange={(e)=> {handleDominio(e)}} /><br/>



        </div>
        <br/> 
       

        <div className='section'>

            
        <label>
         Acepto ser el representante legal
        </label><input selected  type="checkbox" checked={representante} onChange={handleResponable} className="checkbox" value={representante}/>
        <br/>


            </div>
        <br/>
    
        <div className='section'>

        <label>
          Número de licencias:
        </label><br/>
        <input type="text" value={numlicencias} required onChange={(e)=> {handleNumLicencias(e)}} /><br/>
        <br/>
     


        </div>
      
        <div className='section'>

            <label>
            Tipo de licencias:
            </label><br/>
            <select onSelect={(e)=>handleTipoLicencias(e)}>
            <option value="grapefruit">Business Starter</option>
            <option selected value="lime">Business Standard</option>
            <option value="coconut">Cloud Enterprise</option>
            
            </select>


            </div>

               
        <input type="submit" value="Submit" className='submit'/>
      </form>
    </header>
    </div>

   </div>
    
  );
}
 
export default RegisterForm;