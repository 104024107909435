import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './styles/login-button.css'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      className="login-button"
      onClick={() => loginWithRedirect({
        screen_hint: "login",
      })}
    >
     <i className="fas fa-lock icon-left"></i>
     Ingresar
    </button>
  );
};

export default LoginButton;