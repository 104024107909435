// import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';
import {Button} from 'react-bootstrap';
import React, {Component} from 'react';
class clientLogin  extends Component{
	render () {
    return(
    <div className="clientLogin">
        <Header/> 
        <section id="content">
			{/* <!-- Content Row --> */}
			<section className="content-row content-row-color content-row-clouds">
				<div className="container">
					<header className="content-header content-header-small content-header-uppercase">
						<h1>
							Login
						</h1>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
						</p>
					</header>
				</div>
			</section>
			{/* <!-- Content Row --> */}
			<section className="content-row">
				<div className="container">
					<div className="column-row align-center">
						<div className="column-50">
							<form className="form-full-width" method="get" action="client-login.html">
								<div className="form-row">
									<label for="form-email">Correo</label>
									<input id="form-email" type="text" name="email"/>
								</div>
								<div className="form-row">
									<label for="form-password">Contraseña</label>
									<input id="form-password" type="password" name="password"/>
								</div>
								<div className="form-row">
									<input id="form-login" type="checkbox" name="login" checked/>
									<label for="form-login">Guardar mi sesión en este equipo . </label>
								</div>
								{/* <div className="form-row">
									<button className="button-secondary" ><i className="fas fa-lock icon-left"></i>Confirmar</button>
								</div> */}
								<div className="form-row">
								<Button variant="primary" style={{color: "#4a5669", background: "#ffcc33", border:"none" , pading: "18 22"}}><i className="fas fa-lock icon-left"></i>Confirmar </Button>
								</div>
								
							</form>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Content Row --> */}
			<section className="content-row content-row-gray">
				<div className="container">
					<div className="column-row align-center">
						<div className="column-50 text-align-center">
							<p className="text-color-gray">
								Tienes problemas al accesar <br/>
								<a href="/reset-password">Resetear contraseña<i className="fas fa-angle-right icon-right"></i></a>
							</p>
						</div>
					</div>
				</div>
			</section>
		</section>
        <Footer/>
     
    </div>
    )
  }
}

export default clientLogin;