import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';

const PageNotFound = () => {
    return(
    <div className="404Page">
        <Header/> 
        <section id="content">
			
			<section className="content-row content-row-color content-row-clouds content-row-half-height">
				<div className="container">
					<div className="column-row align-center">
						<div className="column-33 text-align-center">
							<i className="fas fa-exclamation-circle icon-feature-large text-color-light"></i>
						</div>
						<div className="column-50">
							<header className="content-header content-header-large content-header-align-left">
								<h3>
									Error 404
								</h3>
								<p>
									Lo sentimos pero la pagina que estas buscando no fue encontrada.<br/><br/>
									<a className="button button-secondary" href="/">Regresar al Home<i className="fas fa-chevron-right icon-right"></i></a>
								</p>
							</header>
						</div>
					</div>
				</div>
			</section>
		</section>
        <Footer/>
     
    </div>
    )
}

export default PageNotFound