import './main.min.css';
import'./template/css/main.css';

const Footer = () => {
	return (
    <div className="footer-dark">
		<footer id="footer">
			<section className="footer-primary">
				<div className="container">
					<div className="column-row">
						<div className="column-50">
							{/*<h5>
								Acerca de Nuberia
							</h5>
							 <p>
								Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...<br/>
								+1 800 123 456
							</p> */}
						</div>
						<div className="column-50">
							<div className="column-row align-right-top">
								<div className="column-25">
									<h5>
										Connect
									</h5>
									<ul className="list-style-icon">
										{/* <li>
											<a href="#facebook"><i className="fab fa-facebook"></i>Facebook</a>
										</li>
										<li>
											<a href="#twitter"><i className="fab fa-twitter"></i>Twitter</a>
										</li>
										<li>
											<a href="#github"><i className="fab fa-github"></i>Github</a>
										</li> */}
										<li>
											<a href="https://www.linkedin.com/company/nuberia/about/"><i className="fab fa-linkedin"></i>LinkedIn</a>
										</li>
									</ul>
								</div>
								<div className="column-25">
									<h5>
										Products
									</h5>
									<ul>
										<li>
											<a href="/products-google-workspace">Google Workspace</a>
										</li>
										
										{/* <li>
											<a href="/products-domain-page">Busqueda de dominios </a>
										</li> */}
										<li>
											<a href="/products-workspace-education"> Workspace for Education</a>
										</li>
										
									</ul>
								</div>
								<div className="column-25">
									<h5>
										Navigation
									</h5>
									<ul>
										
										
										
										
										<li>
											<a href="/acerca-de-nuberia">Acerca de Nuberia</a>
										</li>
										<li>
											<a href="/contacto">Contacto </a>
										</li>
									</ul>
								</div>
								<div className="column-flex">
									<h5>
										Resources
									</h5>
									<ul>
										<li>
											<a href="/login">Ingresar</a>
										</li>
										<li>
											<a href="https://www.nuberia.com/legal/privacidad.pdf">Politica de privacidad</a>
										</li>
										<li>
											<a href="https://workspace.google.com/terms/sla.html">Terminos de servicio </a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="footer-secondary">
				<div className="container">
					<p>
						Copyright 2021 &copy; Nuberia. Todos los derechos reservados.<br/>
						Powered by HTML5, React 
					</p>
				</div>
			</section>
		</footer>
    </div>
		  
	  );
}
 
export default Footer;