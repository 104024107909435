import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';
import logoGmail from './img/Workspace_pngs/Gmail/logo_gmail_2020q4_color_1x_web_32dp.png';
import logoClassroom from "./img/logos/classroom32.png";
import logoAssignments from "./img/logos/assigments32.png";
import logoCloudSearch from './img/logos/cloud-search.svg';
import logoDrive from "./img/Workspace_pngs/Drive/logo_drive_2020q4_color_1x_web_32dp.png" ;
import logoMeet from "./img/Workspace_pngs/Meet/logo_meet_2020q4_color_1x_web_32dp.png";
import logoCalendar from "./img/Workspace_pngs/Calendar/logo_calendar_2020q4_color_1x_web_32dp.png";
const productWorkspaceEducation = () => {
    return(
    <div className="productWorkspaceEducation">
        <Header/> 
		<section id="content">
			
			<section className="content-row content-row-color content-row-clouds content-row-half-height">
				<div className="container">
					<header className="content-header content-header-small content-header-uppercase">
						<h1>
							Workspace for Education
						</h1>
						<p>
						Herramientas de productividad y colaboración para organizaciones educativas.
						</p>
					</header>
					<div className="column-row align-center" id='#feautures'>
						<div className="column-row align-center-bottom">
										<div className="column-33">
											<div className="product-box">
												<div className="product-header">
													<h4>
														Education Fundamentals
													</h4>

												</div>
												<div className="product-price">
													<span className="term">Comenzar gratis<sup>1</sup></span>
												</div>
												<div className="product-features">
												<ul>
													
													<li>
														&#8226; <strong>100 participantes</strong> por videoconferencia .
													</li>
													<li>
														&#8226; <strong>100 TB</strong>  compartidos en el dominio.
													</li>
												</ul>
												<ul>
													<li>
														&#8226; Herramientas de colaboración como Google Classroom, Documentos, Hojas de cálculo, Formularios y mucho más.
													</li>
													<li>
														&#8226; Herramientas de comunicación como Gmail, Meets y Chats.
													</li>
													<li>
														&#8226; Herramientas de prevencion de perdida de información para Gmail y Drive.
													</li>
												</ul>
											</div>
												<div className="product-order">
													<a className="button button-secondary" href="products-google-workspace.html#cloud-starter-yearly">
														<i className="fas fa-shopping-cart icon-left"></i>Order Now
													</a>
												</div>
											</div>
										</div>
										<div className="column-33">
											<div className="product-box ">
												
												<div className="product-header">
													<h4>
														Education Standard
													</h4>
													<p>
														
													</p>
												</div>
												<div className="product-price">
													<span className="term">Consultar Precios</span>
												</div>
												<div className="product-features">
												<ul>
													
													<li>
														&#8226; <strong>100 participantes</strong> por videoconferencia y función de grabación.
													</li>
													<li>
														&#8226; <strong>100 TB</strong>  compartidos en el dominio.
													</li>
												</ul>
												<ul>
													<li>
														<strong>Incluye todo lo que contiene Education Fundamentals ademas de:</strong> 
													</li>
													<li>
														&#8226; Herramientas de administración de dispositivos y apps para auditoría.
													</li>
													<li>
														&#8226; Centro de seguridad para prevenir de forma proactiva.
													</li>
												</ul>
											</div>
												<div className="product-order">
													<a className="button button-secondary" href="products-google-workspace.html#cloud-business-yearly">
														<i className="fas fa-shopping-cart icon-left"></i>Order Now
													</a>
												</div>
											</div>
										</div>
										<div className="column-33">
											<div className="product-box">
												<div className="product-header">
													<h4>
													Teaching and learning upgrade
													</h4>

												</div>
												<div className="product-price">
													<span className="term">Consultar Precios</span>
												</div>
												<div className="product-features">
												<ul>
													
													<li>
														&#8226; <strong>250 participantes</strong> por videoconferencia, función de grabación y seguimiento de asistencia.
													</li>
													<li>
														&#8226; Transmisiones en vivo con hasta <strong>10,000 usuarios del dominio. </strong>    
													</li>
													<li>
														&#8226; <strong>100 TB</strong>  compartidos en el dominio + <strong> 100 GB </strong>  por licencia.
													</li>
													
												</ul>
												<ul>
													<li>
														<strong>Incluye todo lo que contiene Education Fundamentals ademas de:</strong> 
													</li>
													<li>
														&#8226; <strong>Funciones premium en Google Meet</strong> como  Q&A , encuestas y mucho más. 
													</li>
													<li>
														&#8226; Controles de seguridad y administración mejorados, incluidos Vault y gestión avanzada de extremos.
													</li>
													
												</ul>
											</div>
												<div className="product-order">
													<a className="button button-secondary" href="products-google-workspace.html#cloud-enterprise-yearly">
														<i className="fas fa-shopping-cart icon-left"></i>Order Now
													</a>
												</div>
											</div>
										</div>
										<div className="column-33">
											<div className="product-box product-box-popular">
											<div className="product-popular">
													Más popular
												</div>
												<div className="product-header">
													<h4>
													Education Plus
													</h4>

												</div>
												<div className="product-price">
													<span className="term">Consultar Precios</span>
												</div>
												<div className="product-features">
												<ul>
													
													<li>
														&#8226; <strong>250 participantes</strong> por videoconferencia, función de grabación y seguimiento de asistencia.
													</li>
													<li>
														&#8226; Transmisiones en vivo para <strong>100,000 usuarios del dominio.</strong> 
													</li>
													<li>
														&#8226; <strong>100 TB</strong>  compartidos en el dominio + <strong> 20 GB </strong>  por licencia.
													</li>
													
												</ul>
												<ul>
													<li>
														<strong>Incluye todo lo que contiene Education Standard y Teaching and Learning upgrade ademas de:</strong> 
													</li>
													<li>
														&#8226; <strong>Cloudsearch</strong> personalizado para tu dominio.
													</li>
													<li>
														&#8226; Tiempos de Respuesta más rápidos. 
													</li>
												</ul>
											</div>
												<div className="product-order">
													<a className="button button-secondary" href="products-google-workspace.html#cloud-enterprise-yearly">
														<i className="fas fa-shopping-cart icon-left"></i>Order Now
													</a>
												</div>
											</div>
										</div>
						</div>				
								
					</div>
					
					<div className="column-row align-center">
						<div className="column-50">
							<p className="text-align-center">
								<small className="text-color-gray"><sup>1</sup>Google Workspace for Education Fundamentals es gratis para las instituciones que cumplan con los requisitos.</small>
							</p>
						</div>
					</div>

				</div>
			</section>
			<section className="content-row ">
				<div className="container"> 
				<header className="content-header">
						<h2>
							Google Workspace for Education Features
						</h2>
						<p>
							  <span class="text-color-secondary">Google<sup>&reg;</sup></span>
						</p>
					</header>
					<table class="table-layout-product-comparison">
						<thead>
							<tr>
								<th></th>
								<th>Education Fundamentals</th>
								<th>Education Standard</th>
								<th>Teaching and learning upgrade</th>
								<th>Education Plus</th>
							</tr>
						</thead>
						<tbody>
							<tr class="product-header">
								<td></td>
								<td>
									<h4>
										Education Fundamentals
									</h4>
									<p>
										
									</p>
									<p class="product-price">
										<span class="term">por usuario al mes </span>
									</p>
								</td>
								<td>
									<h4>
										Education Standard
									</h4>
									<p>
										
									</p>
									<p class="product-price">
										<span class="term"> por usuario al mes</span>
									</p>
								</td>
								<td>
									<h4>
										Teaching and learning upgrade 
									</h4>
									<p>
										
									</p>
									<p class="product-price">
										<span class="term">por usuario al mes</span>
									</p>
								</td>
								<td>
									<h4>
										Education Plus 
									</h4>
									<p>
										
									</p>
									<p class="product-price">
										<span class="term">por usuario al mes</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>Reuniones de video y de voz</td>
								<td><b>100</b> participantes</td>
								<td><b>100</b> participantes y <b> función de grabación</b></td>
								<td><b>250</b> participantes, función de grabación y <b>seguimiento de asistencia.</b></td>
								<td><b>250</b> participantes, función de grabación y <b>seguimiento de asistencia.</b></td>
							</tr>
							<tr>
								<td>Almacenamiento en la nube seguro</td>
								<td><b>100 TB</b> compartidos para el dominio. </td>
								<td><b>100 TB</b> compartidos para el dominio.</td>
								<td><b>100 TB</b> compartidos para el dominio.</td>
								<td><b>100 TB</b> compartidos para el dominio.</td>
								
							</tr>
							
							<tr class="product-order">
								<td></td>
								<td>
									<a class="button button-secondary" href="https://forms.gle/Kp3UW6oYRnQsKLGx7">
										<i class="fas fa-shopping-cart icon-left"></i>Ordenar 
									</a>
								</td>
								<td>
									<a class="button button-secondary" href="https://forms.gle/Kp3UW6oYRnQsKLGx7">
										<i class="fas fa-shopping-cart icon-left"></i>Ordenar
									</a>
								</td>
								<td>
									<a class="button button-secondary" href="https://forms.gle/Kp3UW6oYRnQsKLGx7">
										<i class="fas fa-shopping-cart icon-left"></i>Ordenar
									</a>
								</td>
								<td>
									<a class="button button-secondary" href="https://forms.gle/Kp3UW6oYRnQsKLGx7">
										<i class="fas fa-shopping-cart icon-left"></i>Ordenar
									</a>
								</td>
							</tr>

							<tr class="product-feature-group">
								<td> <a class="logo" href="https://workspace.google.com/intl/es-419/products/gmail/">
									<img src={logoGmail} alt="" 
									className='img-fluid hover-shadow'
									
									style={{ width: '32px' }}/>

									</a>
								<p> <strong>Gmail</strong> Correo electrónico para toda la institución</p>
								</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
									
							</tr>
							<tr class="product-feature-group">
								<td> <a class="logo" href="https://workspace.google.com/intl/es-419/products/calendar/">
									<img src={logoCalendar} alt="" 
									className='img-fluid hover-shadow'
									
									style={{ width: '32px' }}/>

									</a>
								<p> <strong>Calendar</strong> Calendarios compartidos para la institución </p>
								</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
									
							</tr>

							<tr class="product-feature-group">
								<td><a class="logo" href="https://workspace.google.com/intl/es-419/products/meet/"
								className='img-fluid hover-shadow'
								
								style={{ width: '32px' }} >
									<img src={logoMeet} alt="" className='img-fluid hover-shadow'
      									
      									style={{ width: '32px' }}/>
									</a> 
									<p><strong>Meet</strong> videoconferencia y reuniones de voz </p></td>
									<td>100 participantes</td>
									<td>100 participantes</td>
									<td>250 participantes</td>
									<td>250 participantes</td>		
								</tr>
							<tr>
								<td>Acceso telefónico a reuniones</td>
								<td>Solo en Estados Unidos</td>
								<td>Solo en Estados Unidos</td>
								<td>Solo en Estados Unidos</td>
								<td>Estados Unidos y otros paises</td>
							</tr>
							<tr>
								<td>Transmisión en vivo en el dominio<sup>2</sup> </td>
								<td><strong>-</strong></td>
								<td><strong>-</strong> </td>
								<td> hasta 10,000 usuarios</td>
								<td> hasta 100,000 usuarios</td>
							</tr>
							<tr>
								<td>Subtitulos iniciados por usuarios</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Uso de pizarra digital</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Controles de moderación<sup>2</sup></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Levantar la mano</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Reducción del ruido</td>
								<td><strong>-</strong></td>
								<td><strong>-</strong></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Seguimiento de asistencia<sup>2</sup> </td>
								<td><strong>-</strong></td>
								<td><strong>-</strong> </td>
								<td> <i class="fas fa-check text-color-primary"></i></td>
								<td> <i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Sesiones separadas </td>
								<td><strong>-</strong></td>
								<td><strong>-</strong> </td>
								<td> <i class="fas fa-check text-color-primary"></i></td>
								<td> <i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr class="product-feature-group">
								<td> <a class="logo" href="https://workspace.google.com/intl/es-419/products/drive/">
									<img src={logoDrive} alt="" href="home-single-page.html"
									className='img-fluid hover-shadow'
									
									style={{ width: '32px' }}/>
								</a>
								<p> <strong>Drive</strong> Correo electrónico para toda la institución</p>
								</td>
								<td>100 TB compartidos </td>
								<td>100 TB compartidos </td>
								<td>100 TB compartidos  + 100 GB por licencia </td>
								<td>100 TB compartidos + 20GB por licencia</td>
									
							</tr>
							<tr class="product-feature-group">
								<td> <a class="logo" href="https://workspace.google.com/intl/es-419/products/cloud-search/">
									<img src={logoCloudSearch} alt="" 
									className='img-fluid hover-shadow'
									
									style={{ width: '32px' }}/></a>
								<p> <strong>Cloud Search</strong> Busqueda inteligente en tu dominio </p>
								</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
									
							</tr>
							
							
							<tr class="product-feature-group">
								<td><a class="logo" href="https://edu.google.com/intl/es-419/assignments/" >
									<img src={logoAssignments} alt="" className='img-fluid hover-shadow'
      									
      									style={{ width: '32px' }}/></a> 
								<p><strong> Tareas </strong> califica desde cualquier lugar y ahorra tiempo</p>
								</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
									
									
							</tr>
							<tr>
								<td>Informes de originalidad</td>
								<td>5 por clase</td>
								<td>5 por clase</td>
								<td>Ilimitado con comparación de pares</td>
								<td>Ilimitado con comparación de pares</td>
							</tr>
							<tr class="product-feature-group">
								<td><a class="logo" href="https://edu.google.com/products/classroom/" >
										<img src={logoClassroom} alt="" className='img-fluid hover-shadow'
      									style={{ width: '32px' }}/>
									</a> 
									<p><strong>Classroom</strong> evalua el progreso de los alumnos desde cualquier lugar </p></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>	
								</tr>
							<tr>
								<td>Complementos de apps de terceros</td>
								<td><strong>-</strong></td>
								<td><strong>-</strong></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Informes de originalidad</td>
								<td>5 por clase</td>
								<td>5 por clase</td>
								<td>Ilimitado con comparación de pares</td>
								<td>Ilimitado con comparación de pares</td>
							</tr>
							
							
							
						</tbody>
					</table>
					<div class="column-row align-center">
						<div class="column-50 text-align-center">
							<p>
								<small class="text-color-gray"><sup>2</sup> Esta función no es aplicable a las licencias para alumnos. </small>
							</p>
						</div>
					</div>
				</div>
		</section>
		</section>
        <Footer/>
     
    </div>
    )
}

export default productWorkspaceEducation;