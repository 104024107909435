import React from 'react';
import Head from './Head';
import Header from './Header';
import Footer from './Footer';
// import Mensual from './TabPrecioMensual';
// import TabPrecioAnual from './TabPrecioAnual';
import './template/css/fontawesome.min.css';
import './main.min.css';
import BoxesWorkspace from './BoxesWorkspace';
//import TabPrecioAnual from "./TabPrecioAnual";
// import {Tabs, Tab} from 'react-bootstrap-tabs';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import {Tabs, Tab} from 'react-bootstrap-tabs';
import 'react-tabs/style/react-tabs.css';

const homeSinglePage = () => {
	return (
		<div className="homeSinglePage">
			<Head />
			<Header />
			<section id="content">
				<section className="content-row content-row-color content-row-clouds" >
					<div className="container"  style={{ display: "flex", justifyContent: "space-between" }}>
						<header class="content-header content-header-large content-header-uppercase" >
							<h1>
								<mark>Google</mark> Workspace
							</h1>
							<p>
								Ofimática nivel empresarial de  <span class="text-color-secondary">Google<sup>&reg;</sup></span>
							</p>
						</header>
					</div>
					
					{/* <Tabs className="tab-group tab-group-switch-style" 
					style={{
						backgroundColor: '#0098ef',
						color: '#fff'
            			}}
						defaultIndex={1}	  
						onSelect={(index, label) => console.log(label + ' selected')}>
                            <Tab label="Mensua"  className="tab-item" ><Mensual/></Tab>
                            <Tab label="Anual" className="tab-item"><TabPrecioAnual/></Tab>
                    </Tabs> */}
					<div class="container">
						<p>

						</p>

						<Tabs className="tab-group tab-group-switch-style" onSelect={(index, label) => console.log(label + '.active')}>
							<TabList>
								<Tab>Mensual</Tab>
								<Tab>Anual</Tab>
							</TabList>
							<p>
								
							</p>

							<TabPanel>

								<div className="column-row align-center-bottom">

									<div className="column-33">
										<div className="product-box">
											<div className="product-header">
												<h4>
													Business Starter
												</h4>

											</div>
											<div className="product-price">
												$6 USD <span className="term">/ usuario mes</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>100 participantes</strong> por videoconferencia
													</li>
													<li>
														<strong>30 GB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro
													</li>
													<li>
														Controles de seguridad y administración
													</li>
													<li>
														Asistencia estándar
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="/registro">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>

									<div className="column-33">
										<div className="product-box product-box-popular">
											<div className="product-popular">
												Más popular
											</div>
											<div className="product-header">
												<h4>
													Business Standard
												</h4>

											</div>
											<div className="product-price">
												$12 USD <span className="term">/ usuario mes</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>150 participantes</strong> por videoconferencia y función de grabación
													</li>
													<li>
														<strong>2 TB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro
													</li>
													<li>
														Controles de seguridad y administración
													</li>
													<li>
														Asistencia estándar (posibilidad de pagar la actualización al plan de Asistencia mejorada)
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="/registro">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>

									<div className="column-33">
										<div className="product-box">
											<div className="product-header">
												<h4>
													Cloud Enterprise
												</h4>
												<p>
													{/* Enterprise content management */}
												</p>
											</div>
											<div className="product-price">
												$18 USD <span className="term">/ usuario mes</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>250 participantes</strong> por videoconferencia, función de grabación y seguimiento de asistencia
													</li>
													<li>
														<strong>5 TB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro, detección electrónica y retención
													</li>
													<li>
														Controles de seguridad y administración mejorados, incluidos Vault y gestión avanzada de extremos
													</li>
													<li>
														Asistencia estándar (posibilidad de pagar la actualización al plan de Asistencia mejorada)
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="/registro">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>
								</div>
							</TabPanel>
							<TabPanel>
								<div className="column-row align-center-bottom">
									<div className="column-33">
										<div className="product-box">
											<div className="product-header">
												<h4>
													Business Starter
												</h4>

											</div>
											<div className="product-price">
												$72 USD <span className="term">/ usuario al año</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>100 participantes</strong> por videoconferencia
													</li>
													<li>
														<strong>30 GB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro
													</li>
													<li>
														Controles de seguridad y administración
													</li>
													<li>
														Asistencia estándar
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="/registro">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>
									<div className="column-33">
										<div className="product-box product-box-popular">
											<div className="product-popular">
												Más popular
											</div>
											<div className="product-header">
												<h4>
													Business Standard
												</h4>
												<p>
													High traffic corporate sites
												</p>
											</div>
											<div className="product-price">
												$144 USD <span className="term">/ usuario al año</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>150 participantes</strong> por videoconferencia y función de grabación
													</li>
													<li>
														<strong>2 TB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro
													</li>
													<li>
														Controles de seguridad y administración
													</li>
													<li>
														Asistencia estándar (posibilidad de pagar la actualización al plan de Asistencia mejorada)
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="/registro">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>
									<div className="column-33">
										<div className="product-box">
											<div className="product-header">
												<h4>
													Cloud Enterprise
												</h4>

											</div>
											<div className="product-price">
												$216 USD <span className="term">/ usuario al año</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>250 participantes</strong> por videoconferencia, función de grabación y seguimiento de asistencia
													</li>
													<li>
														<strong>5 TB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro, detección electrónica y retención
													</li>
													<li>
														Controles de seguridad y administración mejorados, incluidos Vault y gestión avanzada de extremos
													</li>
													<li>
														Asistencia estándar (posibilidad de pagar la actualización al plan de Asistencia mejorada)
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="/registro">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>
								</div>
							</TabPanel>
						</Tabs>
					</div>

				</section>
				<section className="content-row">

				</section>
				<section className="content-row">
					<BoxesWorkspace />
				</section>
			</section>

			<Footer />
		</div>
	)
}

export default homeSinglePage