import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';

const resetPassword = () => {
    return(
    <div className="clientLogin">
        <Header/> 
        <section id="content">
			{/* <!-- Content Row --> */}
			<section className="content-row content-row-color content-row-clouds">
				<div className="container">
					<header className="content-header content-header-small content-header-uppercase">
						<h1>
							Reset password
						</h1>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
						</p>
					</header>
				</div>
			</section>
			{/* <!-- Content Row --> */}
			<section className="content-row">
				<div className="container">
					<div className="column-row align-center">
						<div className="column-50">
							<form className="form-full-width" method="get" action="client-login.html">
								<div className="form-row">
									<label for="form-email">Correo</label>
									<input id="form-email" type="text" name="email"/>
								</div>

								<div className="form-row">
									<button className="button-secondary"><i className="fas fa-lock icon-left"></i>Confirmar</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			
			
		</section>
        <Footer/>
     
    </div>
    )
}

export default resetPassword;