import './main.min.css';

import React, { useState } from "react";

import AuthNav from './components/auth/auth-nav'
const NavBar= () => {
   
   
    return ( 
       
        <nav>
			<ul className="nav-primary">
                
                <li>
                    <a>Productos</a>
                    <ul>
                        <li>
                            <a href="/products-workspace">Google Workspace</a>
                        </li>
                        
                        {/* <li>
                            <a href="/products-domain-page">Busqueda de dominios</a>
                        </li>
                         */}
                        <li>
                            <a href="/products-workspace-education">Workspace for Education</a>
                        </li>
                    </ul>

                </li>
                <li>
                    <a href="/products-administracion">Administración</a>
                </li>
                <li>
                    <a href="/migraciones">Migraciones</a>
                </li>

               
                <li>
                    <AuthNav />
   
                    
                    
                </li>
            </ul>
        </nav>
       
        
     );
}
 
export default NavBar;

// {isAuthenticated ? (
//     <LogoutButton />
// {isAuthenticated ? (
//     <LogoutButton />
        
//     ) : (
//     <LoginButton />
//     )}
        
//     ) : (
//     <LoginButton />
//     )}