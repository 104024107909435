/* eslint-disable jsx-a11y/heading-has-content */
import logoBN from './g2504.png';
import NavBar from './NavBar';
// import './main.min.css';
import'./template/css/main.css';
import {Link} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Profile } from "./Profile";
const Header = () => {
	const { isAuthenticated } = useAuth0();
	return (
		// <Head/>
		  <header id="header" className="header-dynamic header-shadow-scroll">
				<div className="container">
					<a className="logo" href="/single-page">
					<img src={logoBN} alt="logo" href="home-single-page.html" style={{ width: '170px' }}/>
					</a>
					<nav>
						 <NavBar/> 
	
						<ul className="nav-secondary">
							<li>
								<Link to="/contacto"><i className="fas fa-phone icon-left"></i>Contacto</Link>
							</li>
							
							<li>
								<a href="/acerca-de-nuberia"><i className="fas fa-question-circle icon-left"></i>Acerca de Nuberia</a>
							</li>
							<li>
							{isAuthenticated ? (
							<Profile/>
								
							) : (
							<h6></h6>
							)}
							</li>
						</ul>	
	
						
					</nav>
				</div>
			</header>
		  
	  );
}
 
export default Header;