import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';

const Contact = () => {
    return(
    <div className="Contact">
        <Header/> 
		<section id="content">
			
			<section className="content-row content-row-color content-row-clouds content-row-half-height">
				<div className="container">
					<header className="content-header content-header-small content-header-uppercase">
						<h1>
							Contacto 
						</h1>
						
					</header>
					
					<div class="column-row align-center-bottom text-align-center">
						<div class="column-33">
							
							<i class="fas fa-phone icon-center fa-10x "></i>
							<h3>
								Márcanos:

								<span class="text-color-secondary">+ 52 </span>55 1287 9931
							</h3>
							<p>

							</p>
							
						</div>
						<div class="column-33">
								
								<a class="logo" href= "https://docs.google.com/forms/d/e/1FAIpQLSf5pYPzz-yqIxKW3GIxsUbW8mKufpnCkAW0UAMLyTm7_4vzPA/viewform?usp=sf_link">
									<i class="fas fa-question-circle icon-center fa-10x " alt=""></i>
							</a>
							
							<h3>
								Quieres que te contactemos 
							</h3>
							<p>
								Llena  formulario y nuestro equipo se pondrá en contacto contigo.
							</p>
							
						</div>
						
					</div>

				</div>
			</section>
		</section>
        <Footer/>
     
    </div>
    )
}

export default Contact;