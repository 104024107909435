import React from 'react';

import LoginButton from './login-button';
import LogoutButton from './logout-button';
import axios from 'axios';

import { useAuth0 } from '@auth0/auth0-react';




const AuthenticationButton = () => {
  const { isAuthenticated } = useAuth0();
  
  
  axios.get('users').then((response)=>{
    console.log(response.data)
  })

  return isAuthenticated ? <LogoutButton /> : <LoginButton />;
};

export default AuthenticationButton;