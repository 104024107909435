// import logoBN from './g2504.png';
import'./template/css/main.css';

const TabPrecioAnual = () => {
	return (
		<div className="tab-item" data-title="Anual">
								<div className="column-row align-center-bottom">
									<div className="column-33">
										<div className="product-box">
											<div className="product-header">
												<h4>
													Business Starter
												</h4>

											</div>
											<div className="product-price">
												$72 USD <span className="term">/ usuario al año</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>100 participantes</strong> por videoconferencia
													</li>
													<li>
														<strong>30 GB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro
													</li>
													<li>
														Controles de seguridad y administración
													</li>
													<li>
														Asistencia estándar
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="products-google-workspace.html#cloud-starter-yearly">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>
									<div className="column-33">
										<div className="product-box product-box-popular">
											<div className="product-popular">
												Más popular
											</div>
											<div className="product-header">
												<h4>
													Business Standard
												</h4>
												<p>
													High traffic corporate sites
												</p>
											</div>
											<div className="product-price">
												$144 USD <span className="term">/ usuario al año</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>150 participantes</strong> por videoconferencia y función de grabación
													</li>
													<li>
														<strong>2 TB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro
													</li>
													<li>
														Controles de seguridad y administración
													</li>
													<li>
														Asistencia estándar (posibilidad de pagar la actualización al plan de Asistencia mejorada)
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="products-google-workspace.html#cloud-business-yearly">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>
									<div className="column-33">
										<div className="product-box">
											<div className="product-header">
												<h4>
													Cloud Enterprise
												</h4>

											</div>
											<div className="product-price">
												$216 USD <span className="term">/ usuario al año</span>
											</div>
											<div className="product-features">
												<ul>

													<li>
														<strong>250 participantes</strong> por videoconferencia, función de grabación y seguimiento de asistencia
													</li>
													<li>
														<strong>5 TB</strong> de almacenamiento en la nube por usuario
													</li>
												</ul>
												<ul>
													<li>
														<strong></strong> Correo electrónico empresarial personalizado y seguro, detección electrónica y retención
													</li>
													<li>
														Controles de seguridad y administración mejorados, incluidos Vault y gestión avanzada de extremos
													</li>
													<li>
														Asistencia estándar (posibilidad de pagar la actualización al plan de Asistencia mejorada)
													</li>
												</ul>
											</div>
											<div className="product-order">
												<a className="button button-secondary" href="products-google-workspace.html#cloud-enterprise-yearly">
													<i className="fas fa-shopping-cart icon-left"></i>Order Now
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						 
	  );
}
 
export default TabPrecioAnual;