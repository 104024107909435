import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';

const productDomainName = () => {
    return(
    <div className="productdomainName">
        <Header/> 
		<section id="content">
			
			<section className="content-row content-row-color content-row-clouds content-row-half-height">
				<div className="container">
					<header className="content-header content-header-small content-header-uppercase">
						<h1>
							Nombres de Dominio
						</h1>
						<p>
							Elige tu nombre de dominio. 
						</p>
					</header>
					<div className="column-row align-center">
						<div className="column-66">
							<form className="form-full-width" method="get" action="products-domain-names.html">
								<div className="group align-center form-row">
									<input className="group-stretch" type="text" name="domain" placeholder="Obten tu nombre de dominio"/>
									<button className="button-secondary"><i className="fas fa-search icon-left"></i>Buscar</button>
								</div>
							</form>
						</div>
					</div>
					<div class="column-row align-center">
						<div class="column-50">
							{/* <p class="text-align-center">
								<small class="text-color-gray">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione amet rerum asperiores qui ...</small>
							</p> */}
						</div>
					</div>

				</div>
			</section>
		</section>
        <Footer/>
     
    </div>
    )
}

export default productDomainName;