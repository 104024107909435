import React, { useState } from 'react';

import AuthenticationButton from './authentication-button';







const AuthNav = () => {


return(
  <div className="navbar-nav ml-auto">
    <AuthenticationButton />
   
   
  </div>
)

}
  


export default AuthNav;