import React from 'react';
import  ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';


const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
    <App />
    </Auth0ProviderWithHistory>
  
  </React.StrictMode>
,root);

reportWebVitals();