import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';
import logoAdmin from './img/Workspace_pngs/Admin/logo_admin_2020q4_color_1x_web_96dp.png';
import './productAdmin.css'

const productAdmin = () => {
    return(
    <div className="productAdmin">
        <Header/> 
		<section id="content">
			
			<section id="admin" className="content-row content-row-color content-row-clouds content-row-half-height">
				<div className="container">
					<header className="content-header content-header-small content-header-uppercase header-text">
						<h2>
						Administración completa de tus subscripciones, usuarios y consola de Google Workspace
						</h2>
						<br />
						<p>
						Deja en manos de expertos la administración de Workspace y enfócate en tu negocio.
						</p>
					</header>
					
					<div class="container">
						
						<div class="column-row align-center-bottom text-align-center">
							<div class="column-33">
								<img src={logoAdmin} alt="" className='logoAdmin'/>
								<h3>
									Administración
								</h3>
								<p>
									Administramos tu consola.
								</p>
								
							</div>
							
							
						</div>
						
					</div>

				</div>
			</section>
		</section>
        <Footer/>
     
    </div>
    )
}

export default productAdmin;