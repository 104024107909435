import './main.min.css';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import React, {  useEffect } from 'react';
import TabPrecioAnual from "./TabPrecioAnual";
import TabPrecioMensual from "./TabPrecioMensual";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import Head from './Head';

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';



function Home () {  
    axios.defaults.baseURL = 'http://localhost:5000'
    const { user ,isAuthenticated,getAccessTokenSilently,client} = useAuth0();
    
  

    useEffect(()=>{
       const sendUserData=async()=>{
        
        if(isAuthenticated){
            const token = await getAccessTokenSilently();
            console.log(token)
            // const config = {
            //     headers: { Authorization: `Bearer ${token}` }
            // };
            console.log(user)
            const { sub,  email } = user;
            const data ={
                user_id:sub,
                email:email
            }
            console.log(sub,email)
            axios.post('users',data).then(function (response) {
                console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });
       }
        }

        sendUserData()
        
        
        

    })

    return (
        <React.Fragment >
           
        <Head/>
        <Header/> 
        
        <section id="content">
				<section id="pricing" className="content-row content-row-color content-row-clouds content-row-full-height">
					<div className="container">
						<header className="content-header content-header-large content-header-uppercase">
							<h1>
								Google Workspace
							</h1>
							<p>
							Ofimática nivel empresarial de  <span className="text-color-secondary">Google<sup>&reg;</sup></span>
						    </p>
                            <button>registrarse</button>
						</header>
                        </div>
                        </section>    
                        <section  className="content-row">
                        <Tabs className="tab-group tab-group-switch-style" style={{color: "#0098ef"}} onSelect={(index, label) => console.log(label + ' selected')}>
                            <Tab label="Mensual"  className="tab-item"><TabPrecioMensual/></Tab>
                            <Tab label="Anual" className="tab-item"><TabPrecioAnual/></Tab>
                        </Tabs>
                        
					

		</section>
        
        
        </section>
        
        <Footer/>
       
    
        </React.Fragment>
       

    
      );
    }


 
export default Home;