import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';
import logoGWorkspace from './img/logos/Gworkspace256.png';
import logoMicrosoft from './img/logos/microsoft256.png';
import logoImap from './img/logos/imap.png';
import './productMigration.css'


const productMigrations = () => {
    return(
    <div className='productMigrations'>
        <Header/> 
		<section id='content'>
			
			<section className='content-row  content-row-half-height'>
				<div className='container'>
				<header className='content-header content-header-large content-header-uppercase'>
						<h2>
							Migrate  a Google Workspace
						</h2>
						<p>
							<h4>Factura Mexicana con Valor Físcal en México </h4> (Deduce el 100% del IVA)
							
						</p>
					</header>
					
					<div className='column-row align-center-bottom text-align-center'>
						<div className='column-33'>
							<img src={logoGWorkspace} alt=''/>
							<h3>
								Gmail o Google Workspace.
							</h3>
							<p>
								Migra tu subscripcion existente de Google con nosotros
							</p>
						</div>
						<div className='column-33 microsoft'>
							<img src={logoMicrosoft} alt='' className='microsoft-logo' /> 
							<h3>
								Microsoft Exchange
							</h3>
							<p>
								Migra de Microsoft Exchange a Google con nosotros
							</p>
							
						</div>
						<div className='column-33 imap'>
							<img src={logoImap} alt='' className='imap-logo'/>
							<h3>
								IMAP
							</h3> 
							<p>
								Servicios webmail como AOL, Apple iCloud, Bluehost, GoDaddy, Yahoo! o Zoho. 
							</p>
							
						</div>
					</div>

				</div>
			</section>
		</section>
        <Footer/>
     
    </div>
    )
}

export default productMigrations;