import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './template/css/fontawesome.min.css';
import './main.min.css';
import './tabs.css';
import logoGMAil from './img/logos/logo_gmail_2020q4_color_1x_web_96dp.png';
import logoDrive from './img/logos/logo_drive_2020q4_color_1x_web_96dp.png';
import logoMeet from './img/logos/logo_meet_2020q4_color_1x_web_96dp.png';
import TabPrecioAnual from "./TabPrecioAnual";
import TabPrecioMensual from "./TabPrecioMensual";
import {Tabs, Tab} from 'react-bootstrap-tabs';

const productWorkspace= () => {
    return(
    <div className="productWorkspace">
        <Header/> 
		<section id="content">
        <section className="content-row content-row-color content-row-clouds">
				<div className="container">
					<header className="content-header content-header-small content-header-uppercase">
						<h1>
							Google Workspace
						</h1>
						<p>
							Herramientas de productividad y colaboración para todas las formas de trabajo.
						</p>
					</header>
					<div className="container">	
					<Tabs className="tab-group tab-group-switch-style"  onSelect={(index, label) => console.log(label + '.active')}
					style={{ 
						
						color: "#fff",
						backgroundColor: "#0098ef"
					
					}}
					>
                            <Tab label="Mensual"  className="tab-item"  ><TabPrecioMensual/></Tab>
                            <Tab label="Anual" className="tab-item"><TabPrecioAnual/></Tab>
                    </Tabs>
					</div>
					<p>
							<small class="text-color-gray">Los planes Business Starter, Business Standard y Business Plus se pueden comprar para un máximo de 300 usuarios. No existen límites de usuarios para los planes Enterprise.</small>
					</p>
				</div>
		</section>
        

        <section className="content-row ">
				 <div className="container"> 
					<header className="content-header">
						<h2>
							Google Workspace Features
						</h2>
						<p>
							Ofimática nivel empresarial de  <span className="text-color-secondary">Google<sup>&reg;</sup></span>
						</p>
					</header>
					<table className="table-layout-product-comparison">
						<thead>
							<tr>
								<th></th>
								<th>Business Starter</th>
								<th>Business Standard</th>
								<th>Business Plus </th>
							</tr>
						</thead>
						<tbody>
							<tr class="product-header">
								<td></td>
								<td>
									<h4>
										Business Starter
									</h4>
									<p>
										
									</p>
									<p class="product-price">
										$6 USD<span class="term">por usuario al mes </span>
									</p>
								</td>
								<td>
									<h4>
										Business Standard
									</h4>
									<p>
										
									</p>
									<p class="product-price">
										$12 USD<span class="term"> por usuario al mes</span>
									</p>
								</td>
								<td>
									<h4>
										Business Plus 
									</h4>
									<p>
										
									</p>
									<p class="product-price">
										$18 USD<span class="term">por usuario al mes</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>Reuniones de video y de voz</td>
								<td><b>100</b> participantes</td>
								<td><b>150</b> participantes y <b> función de grabación</b></td>
								<td><b>250</b> participantes, función de grabación y <b>seguimiento de asistencia</b></td>
							</tr>
							<tr>
								<td>Almacenamiento en la nube seguro</td>
								<td><b>30 GB</b> por usuario</td>
								<td><b>2 TB</b> por usuario</td>
								<td><b>5 TB</b> por usuario</td>	
							</tr>
							
							<tr class="product-order">
								<td></td>
								<td>
									<a class="button button-secondary" href="/registro">
										<i class="fas fa-shopping-cart icon-left"></i>Ordenar 
									</a>
								</td>
								<td>
									<a class="button button-secondary" href="/registro">
										<i class="fas fa-shopping-cart icon-left"></i>Ordenar
									</a>
								</td>
								<td>
									<a class="button button-secondary" href="/registro">
										<i class="fas fa-shopping-cart icon-left"></i>Ordenar
									</a>
								</td>
							</tr>


							<tr class="product-feature-group">
								<td> Gmail</td>
								<td></td>
								<td></td>
								<td>
									<a class="logo" href="https://workspace.google.com/intl/es-419/products/gmail/">
										<img src={logoGMAil} 
										className='img-fluid hover-shadow'
      									style={{ width: '32px' }}
										alt ="" />
									</a>
								</td>	
							</tr>
							<tr>
								<td>Correo electrónico personalizado para su empresa</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Protección contra la suplantación de identidad (phishing)</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Spam Filter que bloquea más del 99,9% de los ataques</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Experiencia de correo electrónico sin anuncios</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr class="product-feature-group">
								<td>Meet</td>
								<td></td>
								<td></td>
								<td>
									<a class="logo" href="https://workspace.google.com/intl/es-419/products/meet/" >
										<img src={logoMeet} alt="" 										
										className='img-fluid hover-shadow'
      									style={{ width: '32px' }}/>
									</a>
									</td>		
								</tr>
							<tr>
								<td>Reuniones de video y de voz</td>
								<td><b>100 participantes</b></td>
								<td><b>150 participantes</b></td>
								<td><b>250 participantes</b></td>
							</tr>
							<tr>
								<td>Duración (máxima) de la reunión</td>
								<td>24 horas</td>
								<td>24 horas</td>
								<td>24 horas</td>
							</tr>
							<tr>
								<td>Números telefónicos de acceso internacionales o para EE.UU.</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Uso de pizarra digital</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Reducción del ruido</td>
								<td></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Almacenamiento de grabaciones de encuestas en Google Drive</td>
								<td></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Transmisión en vivo para el dominio</td>
								<td><i class="fas fa-times text-color-light"></i></td>
								<td><i class="fas fa-times text-color-light"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr class="product-feature-group">
								<td><b> Drive </b></td>
								<td></td>
								<td></td>
								<td>
									<a class="logo" href="https://workspace.google.com/intl/es-419/products/drive/">
										<img src={logoDrive} alt="" href="home-single-page.html"
																				className='img-fluid hover-shadow'
																				style={{ width: '32px' }} />
									</a>
								</td>	
									
							</tr>
							<tr>
								<td>Almacenamiento en la nube seguro</td>
								<td><b>30 GB por usuario</b></td>
								<td><b>2 TB por usuario</b></td>
								<td><b>5 TB por usuario</b></td>	
							</tr>
							<tr>
								<td>Drive para escritorio</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Compatibilidad con más de 100 tipos de archivos</td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							<tr>
								<td>Unidades compartidas para su equipo</td>
								<td><i class="fas fa-times text-color-light"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
								<td><i class="fas fa-check text-color-primary"></i></td>
							</tr>
							
							
							<tr class="product-feature-group">
								<td>Opciones de Precio (USD)</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Mensual</td>
								<td>$6</td>
								<td>$12</td>
								<td>$18</td>
							</tr>
							
							<tr>
								<td>Anual</td>
								<td>$72</td>
								<td>$144</td>
								<td>$216</td>
							</tr>
						</tbody>
					</table>
					<div className="column-row align-center">
						<div className="column-50 text-align-center">
							<p>
								<small className="text-color-gray">Los planes Business Starter, Business Standard y Business Plus se pueden comprar para un máximo de 300 usuarios.Precios en dolares americanos.</small>
							</p>
						</div>
					</div>
				</div>
		</section>
			
		
		</section>
        <Footer/>
     
    </div>
    )
}

export default productWorkspace;